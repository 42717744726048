/* eslint-disable */
(function () {
  window.warnBrowser = function () {
    var warningElement = document.createElement('div');
    warningElement.className = 'ie-warning';
    var warningContentElement = document.createElement('div');
    warningContentElement.className = 'ie-warning__content';
    warningContentElement.innerHTML = 'We’re sorry, but your browser is currently not supported. Try using Kontent.ai in other browsers like the new Microsoft Edge, Google Chrome, or Mozilla Firefox.';
    warningElement.appendChild(warningContentElement);
    var warningCloserElement = document.createElement('span');
    warningCloserElement.className = 'ie-warning__closer';
    warningCloserElement.innerHTML = '&times;';
    warningContentElement.appendChild(warningCloserElement);
    if (typeof (warningCloserElement.attachEvent) === 'function') {
      warningCloserElement.attachEvent('onclick', function () {
        warningElement.parentNode.removeChild(warningElement);
      });
    }
    else if (typeof (warningCloserElement.addEventListener) === 'function') {
      warningCloserElement.addEventListener('click', function () {
        warningElement.parentNode.removeChild(warningElement);
      });
    }
    else {
      alert('We’re sorry, but your browser is currently not supported. Try using Kontent.ai in other browsers like the new Microsoft Edge, Google Chrome, or Mozilla Firefox.');
    }
    document.body.appendChild(warningElement);
  };
})();
(function () {
  window.warnIfIE = function () {
    if (navigator.userAgent.indexOf('MSIE') > -1 || typeof (document.contains) !== 'function') {
      warnBrowser();
    }
  };
})();
(function () {
  window.warnIfOldEdge = function () {
    if (navigator.userAgent.indexOf('Edge') > -1) {
      warnBrowser();
    }
  };
})();
(function () {
  window.warnIfMissingImplementation = function () {
    if (
      !window.Array.prototype.flatMap
      || !window.ResizeObserver
    ) {
      warnBrowser();
    }
  };
})();
window.onload = function () {
  warnIfIE();
  warnIfOldEdge();
  warnIfMissingImplementation();
};
